// Use instead of $(document).ready()
export const ready = callback => {
  if (document.readyState !== 'loading') {
    callback();
  } else {
    document.addEventListener('DOMContentLoaded', callback);
  }
};

// Checks if an element exists on the page. We use this to filter page-specific js files.
export const elementExists = id => {
  const element = document.getElementById(id);
  return typeof element !== 'undefined' && element !== null;
};

export const setButtonState = (btn, isDisabled) => {
  btn.disabled = isDisabled;
};
